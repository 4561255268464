.backgroundAnimation{
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.fadeAnimation{
  opacity: 0;
}
.modalAnimation{
  opacity: 0;
}

.navbarAnimation, .contentAnimation, .chatAnimation, .chatBarAnimation, .chatInterfaceAnimation, .splashAnimation, .splashLogoAnimation, .userInputAnimation, .reactionAnimation{
  opacity: 0;
}

.reactionAnimation{
  // z-index: ;
}

.testerul{
  position: relative;
  clear:both;
}

// .chatInterfaceAnimation{
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   height: 100vh;
//   width: 100vw;
// }

.splash-text{
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0 auto;
  width: 100vw;
}

.t{
  position: fixed;
  top: 0;
  left: 32px;
  right: 32px;
  height: 100%;
  display: flex;
  align-items: center;
}

.centered{
  margin: 0 auto;
}

@-webkit-keyframes gradientBg {
    0%{background-position:25% 0%}
    50%{background-position:76% 100%}
    100%{background-position:25% 0%}
}
@-moz-keyframes gradientBg {
    0%{background-position:25% 0%}
    50%{background-position:76% 100%}
    100%{background-position:25% 0%}
}
@keyframes gradientBg {
    0%{background-position:25% 0%}
    50%{background-position:76% 100%}
    100%{background-position:25% 0%}
}



@keyframes horizontalScrollAnimation {
  to {
    transform: translateX(-100%);
  }
}


@keyframes shake {
  0% {
    transform: rotate(0deg) scale(1.0);
  }
  15% {
    transform: rotate(-15deg) scale(0.9);
  }
  35% {
    transform: rotate(15deg) scale(1.0);
  }
  50% {
    transform: rotate(-15deg) scale(0.8);
  }
  65% {
    transform: rotate(15deg) scale(1.0);
  }
  85% {
    transform: rotate(-5deg) scale(1.0);
  }
  100% {
    transform: rotate(0deg) scale(1.0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.1);
  }
  10% {
    transform: scale(1.0);
  }
  20% {
    transform: scale(0.1);
  }
  30% {
    transform: scale(1.0);
  }
  40% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(1.0);
  }
  60% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.0);
  }
  80% {
    transform: scale(0.5);
  }
  90% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes button-bg-animation{
  0% {
    background-position: 0%;
  }
  50%{
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}




@keyframes banner-animation {
  0% {
    background: rgba(127, 143, 161, 0.0)no-repeat;
    background-size: 100%;
  }
  50% {
    background: rgba(127, 143, 161, 0.35)no-repeat;
    background-size: 100%;
  }
  100% {
    background: rgba(127, 143, 161, 0.0)no-repeat;
    background-size: 100%;
  }
}




@keyframes Ar-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
/*    background-color: #fff6db;*/
  background-color: #1F2E35;
  color: #fff;
  }
  100% {
    transform: scale(1);
  }
}




@keyframes input-pulse {
  0% {
    background-color: transparent;
  }
  50% {
    
    background-color: #fff6db;
  }
  100% {
    background-color: transparent;
  }
}





@keyframes pink-gradient-animation {
  0% {
    background: radial-gradient(at 50% 0%, rgba(255, 113, 255, 1.0) 0%, rgba(255, 113, 255, 0) 70%) no-repeat;
    background-size: 100%;
    opacity: 0.5;
  }
  50% {
    background: radial-gradient(at 50% 0%, rgba(255, 113, 255, 1.0) 0%, rgba(255, 113, 255, 0) 70%) no-repeat;
    background-size: 150%;
    opacity: 0.0;
  }
  100% {
    background: radial-gradient(at 50% 0%, rgba(255, 113, 255, 1.0) 0%, rgba(255, 113, 255, 0) 70%) no-repeat;
    background-size: 100%;
    opacity: 0.5;
  }
}

// @keyframes blue-gradient-animation {
//   0% {
//     background: radial-gradient(at 0% 50%, rgba(104, 161, 255, 1.0) 0%, rgba(104, 161, 255, 0) 100%) no-repeat;
//     background-size: 100%;
//     opacity: 0.5;
//   }
//   50% {
//     background: radial-gradient(at 0% 50%, rgba(104, 161, 255, 1.0) 0%, rgba(104, 161, 255, 0) 100%) no-repeat;
//     background-size: 150%;
//     opacity: 0.0;
//   }
//   100% {
//     background: radial-gradient(at 0% 50%, rgba(104, 161, 255, 1.0) 0%, rgba(104, 161, 255, 0) 100%) no-repeat;
//     background-size: 100%;
//     opacity: 0.5;
//   }
// }

// @keyframes purple-gradient-animation {
//   0% {
//     background: radial-gradient(at 100% 50%, rgba(106, 113, 255, 1.0) 0%, rgba(106, 113, 255, 0) 100%) no-repeat;
//     background-size: 100%;
//     opacity: 0;
//   }
//   50% {
//     background: radial-gradient(at 100% 50%, rgba(106, 113, 255, 1.0) 0%, rgba(106, 113, 255, 0) 100%) no-repeat;
//     background-size: 150%;
//     opacity: 0.5;
//   }
//   100% {
//     background: radial-gradient(at 100% 50%, rgba(106, 113, 255, 1.0) 0%, rgba(106, 113, 255, 0) 100%) no-repeat;
//     background-size: 100%;
//     opacity: 0;
//   }
// }

// @keyframes aqua-gradient-animation {
//   0% {
//     background: radial-gradient(at 50% 100%, rgba(105, 218, 255, 1.0) 0%, rgba(105, 218, 255, 0) 100%) no-repeat;
//     background-size: 100%;
//     opacity: 0.5;
//   }
//   50% {
//     background: radial-gradient(at 50% 100%, rgba(105, 218, 255, 1.0) 0%, rgba(105, 218, 255, 0) 100%) no-repeat;
//     background-size: 150%;
//     opacity: 0.0;
//   }
//   100% {
//     background: radial-gradient(at 50% 100%, rgba(105, 218, 255, 1.0) 0%, rgba(105, 218, 255, 0) 100%) no-repeat;
//     background-size: 100%;
//     opacity: 0.5;
//   }
// }




@keyframes shine-inner-animation {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(360deg);
    opacity: 0.1;
  }
  100% {
    transform: rotate(0deg);  
    opacity: 1;
  }
}

@keyframes shine-outer-animation {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  25% {
    transform: rotate(-40deg);
    opacity: 0.5;
  }
  50% {
    transform: rotate(0deg);
    opacity: 0;
  }
  75% {
    transform: rotate(20deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0deg);  
    opacity: 1;
  }
}


