// General
h1, h2, h3, h4, p, a{
  font-family: $mainFont;
  color: $solidBlack;
  line-height: $mainLineHeight;

  &.center{
    text-align: center;
  }
}

// Specific 
h1 {
  font-size: $ultraBigTextSize;
  font-weight: $mediumWeight;
  line-height: $smallLineHeight;
  color: $solidBlack;

  &.landing-page-title{
    width: 100%;
    // max-width: 700px;
    // margin-left: 140px;
    margin: 40px 0;
    padding: 0;
    font-size: $ultraBigTextSize;
    font-weight: $semiboldWeight;
    line-height: $noLineHeight;
  }

  &.page-title{
    font-size: $titleTextSize;
    font-weight: $boldWeight;
    line-height: $smallLineHeight;
    margin: 0;
    padding: 0;
  }

  &.splash{
    font-size: $titleTextSize;
    font-weight: $semiboldWeight;
    text-align: center;
    margin: 0 auto;
  }
  
} 

h2{
  font-size: $titleTextSize;
  font-weight: $boldWeight;
  line-height: $smallLineHeight;

}

h3{
  font-size: $subtitleTextSize;
  font-weight: $boldWeight;
  line-height: $smallLineHeight;

  sup{
    font-size: $xsTextSize;
    line-height: $mainLineHeight;
  }

  &.author{
    margin: 0;
    padding: 0;
    line-height: $noLineHeight;
  }
}

h4{
  font-size: $captionTextSize;
  font-weight: $mediumWeight;
  // text-transform: uppercase;
  line-height: $mainLineHeight;
  letter-spacing: 0px;
  

  &.logotype{
    font-size: $xsTextSize;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  &.caption{
    font-size: $xsTextSize;
    margin: 0;

    strong{
      line-height: $mainLineHeight;
    } 
  }

  &.author-title{
    margin: 8px 0;
    // padding: 0;
    line-height: $mainLineHeight;
  }

  &.notification{
    font-size: $xsTextSize;
    font-weight: $semiboldWeight;
    text-align: center;
  }

  &.cat{
    text-transform: uppercase;
    color: $mediumGrey;
  }
}

p{
  font-size: $paragraphTextSize;
  font-weight: $regularWeight;
  line-height: $mainLineHeight;
  color: $solidBlack;

  &.caption{
    font-size: $xsTextSize;
    text-align: center;
  }
  &.contact{
    font-size: $chatTextSize;
    line-height: $mainLineHeight;
    margin: 12px 0;
    padding: 0;
  }
  &.landing{
    font-weight: $mediumWeight;
    font-size: clamp(1.03rem, 0.3rem + 1.13vw, 1.2rem);
    color: $lightGrey;
  }
}

a{
  font-weight: $semiboldWeight;
  text-decoration: none;

  transition: 0.3s ease-in-out;

  // &:hover{
  //   opacity: 0.3;
  // }
} 