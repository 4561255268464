input, textarea{
  font-family: $mainFont;
}

input{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;

  &.reply{
    width: 72%;
    background: transparent;
    border: transparent;
    border-radius: 12px;
    margin: 0 8px;
    // padding: 8px 12px;
    color: $solidBlack;
    font-size: $chatTextSize;
    font-weight: $mediumWeight;
  }

  &.input-field{
    width: calc(100% - 32px);
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.0);
    backdrop-filter: $overlayBlur;
    box-shadow: none;
    border-radius: 16px;
    padding: 16px 16px;
    margin: 4px auto;

    color: $solidBlack;
    font-size: $chatTextSize;
    &:hover{
      border: 1px solid rgba(255, 255, 255, 1.0);
      background: rgba(0, 0, 0, 0.15);
    }
  }

  &.submit-btn{
    background: $solidBlack;
    font-size: $buttonTextSize;
    font-weight: $semiboldWeight;
    padding: 16px;
    color: $solidWhite;
    // margin: 40px auto 0;
    text-align: center;
    display: block;
    width: 65vw;
    height: 48px;
    margin:0 0 8px;
    max-width: 400px;
    border-radius: 48px;
    transition: all 0.3s ease;
    
    &:hover{
      opacity: 0.75;
    }
  }

  &.send-btn{
    text-decoration: none;  
    height: 64px;
    width: 80px;
    background: url('../assets/img/ic-send.svg')no-repeat center center;
    position: absolute;
    right: 0px;
    top:0;
  }

}

input:focus{
  border: transparent;
  color: $solidBlack;
  outline: none;
  opacity: 1;
}

textarea{
    width: calc(100% - 32px);
    background: rgba(0, 0, 0, 0.1);
    // border: 1px solid rgba(255, 255, 255, 1.0);
    backdrop-filter: $overlayBlur;
    box-shadow: none;
    border: none;
    border-radius: 16px;
    padding: 16px 16px;
    margin: 4px auto;

    color: $solidBlack;
    font-size: $chatTextSize;
    &:hover{
      border: 1px solid rgba(255, 255, 255, 1.0);
      background: rgba(0, 0, 0, 0.15);
    }
}

form{
  width:100%;
  max-width: 100%;
  // margin: 0 auto;
  position: relative;
}


.fake-input{
  color: $mediumGrey;
  font-size: $paragraphTextSize;
  font-weight: $regularWeight;
  padding: 0 24px 0 0;
}


