@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

@import 'variables';
@import 'base';
@import 'typography';
@import 'layout';
@import 'components';
@import 'buttons';
@import 'forms';
@import 'model';
@import 'animations';
@import 'responsive';