.landing{
  width: 100vw;
  overflow: hidden!important;
  background: $lightGrey;;
}

.page{
  background: $solidWhite;
  padding: 120px 24px 20px;
  overflow: hidden;
  overflow-y: scroll;

  &.about, &.faq, &.contact{
    padding: 25vh 24px 20px;
  }

  &.onorariu{
    padding: 3vh 24px 20px;
  }
  &.help{
    overflow: hidden;
  }
}

.container{

}

.service{
  // padding: 24px 0px 16px 48px;
  padding: 80px 48px 80px 88px;
  background: $transparentWhite;
  border: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 64px;
  margin: 40px 0;

  &.left{
    margin-right: 140px;
    border-bottom-left-radius: 24px;
  }
  &.right{
    margin-left: 140px;
    border-bottom-right-radius: 24px;
  }
}

.graphics-h{
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.graphics{
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  z-index: 1;

  img{
    display: block;

  }

    // img{
    //   width: 100%;
    //   height: auto;
    //   display: block;
    //   opacity: 0.5;
    // }
}

  .circle-mask{
    background: $lightGrey;
    border-radius: 40vw;
    position: absolute;
    overflow: hidden;
    // border: 2px solid rgba(255, 255, 255, 0.15);
    box-shadow: inset 0px 16px 18px rgba(0, 0, 0, 0.15);
    z-index: 1;

    &.big{
      width: 39vw;
      // max-width: 600px;
      height: 39vw;
      top: 5vh;
      right: -10%;
    }

     &.onorariu{
      position: relative;
      width: 38vw;
      // max-width: 600px;
      height: 38vw;
      margin: 0 auto;
    }

    &.small{
      position: relative;
      float: right;
      width: 10vw;
      // max-width: 600px;
      height: 10vw;
      // top: 90vh;

      // top: 80vh;
      // right: 45vw;
    }
    &.small-mobile{
      width: 33vw;
      max-width: 200px;
      height: 33vw;
      max-height: 200px;
      top: 13vh;
      right: 53vw;

    }

    &.medium{
      width: 30vw;
      // max-width: 600px;
      height: 30vw;
      // top: 83vh;
      top: 110vh;
      right: 4vw;
    }
    &.medium-mobile{
      width: 80vw;
      max-width: 350px;
      max-height: 350px;
      height: 80vw;
      top: 13vh;
      right: -148px;
    }

    &.semi-small{
      width: 15vw;
      // max-width: 200px;
      height: 15vw;
      // top: 16vh;
      top: 0vh;
      right: 35vw;
    }
  }

.splash-screen{
  position: fixed;
  overflow: hidden;
  height: 95vh;
  width: 100vw;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.faq-container{
  border-radius: 40px;
  // background: linear-gradient(-140deg, #D2E8FF, #FFCFCF, #FFCA8F);
  padding-top: 24px;
  margin: 72px 0 0;
  background: linear-gradient(140deg, #d2e8ff, #ffcfcf, #ffca8f);
  background-size: 600% 600%;
}

.color{
  border-radius: 40px;
  // background: linear-gradient(-140deg, #D2E8FF, #FFCFCF, #FFCA8F);
  padding-top: 24px;
  margin: 72px 0 0;
  background: linear-gradient(140deg, #d2e8ff, #ffcfcf, #ffca8f);
  background-size: 600% 600%;

  -webkit-animation: gradientBg 10s ease infinite;
  -moz-animation: gradientBg 10s ease infinite;
  animation: gradientBg 10s ease infinite;
}

.boxed{
  border: 1px solid $lightGrey;
  border-radius: 24px;
  padding: 80px 24px 40px;
}

.grid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max(300px, 32%), 1fr));
  column-gap: 3vw;
  row-gap: 3vw;
  &.xs{
    grid-template-columns: repeat(auto-fit, minmax(max(100px, 32%), 1fr));
  }
  &.fold{

  }
  
  .col{
    position: relative;
    display: flex;
    align-items: center;

    &.top{
      align-items: flex-start;
    }
  }
}

// .grid{
//   display: flex;
//   margin: 20px 64px;

//   .col{
//     margin: 0 24px;
//     width: 50%;
//   }
// }

section{
  position: relative;
  margin: 15vh 0 20vh;

  &.gradient{
    background: linear-gradient(180deg, rgba(45,149,255,0.0) 0%, rgba(255,215,153,0.5) 70%, rgba(45,149,255,0.0) 100%);
  }
}

.content{
  padding: 0 24px;
  max-width: 680px;
  position: relative;
  display: block;
  margin: 0 auto;

  &.left{
    padding: 0;
    margin: 0 16px;
    max-width: 320px;
  }

  &.wide{
    max-width: 88%;
    margin: 0 auto;
    padding: 0;
  }
}

.brands{
  margin: 16px 40px 16px 0;
  padding: 0;
}

hr{
  border: none;
  border: 2px solid $lightGrey;
}

.modal{
  background: $solidWhite;
  position: fixed;
  bottom: 0;
  // left: 0;
  // right: 0;
  // width: 100vw;
  // height: 100%;
  z-index: 6;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0px 10px 100px rgba(0, 0, 0, 1.0);
  overflow: hidden;
}

.modal-overlay{
  position: fixed;
  bottom:0;
  top:0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: $transparentWhite;
  backdrop-filter: $overlayBlur;
  z-index: 5;
}



.calendly-inline-widget{
  // height: 100vh!important;
  // height: 100%!important;
  // position: absolute;
  bottom: 0;
  width: 100vw;
  z-index: 10;
}

footer{

  .main-footer{
    margin-top: 15vh;
    padding: 20% 24px 64px;
    // padding: 48px calc(24px + 6vw) 64px;
    background: #fff;
    border-top: 1px solid $lightGrey;

    .credit{
      float: left;
    }
    .legal{
      float: right;
      font-size: $captionTextSize;
    }
  }

}

.credit-logo{
  display: inline-block;
  width: 153px;
  height: 32px;
  padding: 0;
  background: url('../assets/img/logo-fediuc.svg') no-repeat center center;
}

.sub-footer{
  // background: red;
  width: 100%;
  height: 32px;
}

.logo-list{
  width: 200vw;
}

img{
  width: 100%;
  &.despre{
    border-radius: 24px;
  }
  &.profile{
    width: 100%;
    height: auto;
    max-width: 800px;
    max-height: 800px;
  }

  &.faq{
    width: 48px;
    height: 48px;
    margin: 16px 0 0 16px;
    padding: 0;
  }

  &.small{
    width: 100%;
    height: auto;
    max-width: 140px;
    max-height: 140px;
    margin: 0 auto;
    display: block;
  }

  &.profile-small{
    width: 100%;
    height: auto;
    max-width: 250px;
    max-height: 250px;
    border-radius: 50%;
    // margin: 0 auto;
   
  }

  &.main-logo{
    width: 150px;
    margin-left: 6px;
  }

  &.logo{
    width: auto;
    max-height: 80px;
    display: inline-block;
    // height: auto;
  }

  &.right{
    // position: absolute;
    // right: 24px;
    // top: -10%;
    width: 100%;
    max-width: 800px;
    border-radius: 40px;
  }

  &.chat-logo{
    width: auto;
    height: 40px;
    max-height: 40px;
  }
  
}

iframe{
  border: none;
  height: 73vh;

  /* Hide scrollbar */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

// .App{
//   text-align: center;
//   margin: 8px;
//   position: relative;
// }

// h1{
//   font-size: 48px;
//   font-weight: 300;
// }

// p{
//   line-height: 1.6;
//   font-size: 21px;
//   padding: 8px;
// }

// a{
//   text-transform: uppercase;
//   color: #000;
//   font-weight: 500;
//   font-size: 14px;
// }

// img{
//   display: none;
// }

// .footer{

// }

// .credit{
//   position: absolute;
//   left: 0;
// }

// .credit, .credit a{
//   font-size: 10px;
//   text-transform: uppercase;
  
// }

// a.legal{
//   text-transform: uppercase;
//   text-decoration: none;
//   position: absolute;
//   right: 0;
// }

// a.despre-btn{
//   text-decoration: none;
//   position: fixed;
//   right: 24px;
//   top: 8px;
//   height: 32px;
//   width: 32px;
//   background: url('../assets/img/ic-info.svg')no-repeat center center;
// }

// /*.programare{
//   border: 1px solid #000;
//   border-radius: 21px;
//   padding: 10px;
//   max-width: 400px;
// }*/

// .button{
//   border: none;
//   border-radius: 16px;
//   font-size: 16px;
//   font-weight: 400;
// }

// .main-btn{
//   background: #000;
//   color: #fff;
//   width: 100%;
//   height: 48px;
// }

// .schedule-btn{
//   border-radius: 32px;
//   height: 32px;
//   width: auto;
//   background: linear-gradient( 90deg, #FFB6E8 0%, #858DFF 100%);
//   color: #fff;
//   position: fixed;
//   padding: 0 16px;
//   right: 72px;
//   top: 8px;
// }

// .reply-btn{
//   background: linear-gradient( 90deg, #FFB6E8 0%, #858DFF 100%);
//   color: #fff;
//   height: 48px;
//   padding: 4px 12px;
//   position: absolute;
//   right: 4px;
//   top: 4px;
// }

// .secondary-btn{
//   background: rgba(255, 255, 255, 0.1);
//   color: #000;
//   width: 100%;
//   height: 48px;
// }


// .close-btn{
//   height: 48px;
//   width: 48px;
//   background: rgba(255,255,255, 0.7) url(../assets/img/ic-close.svg)no-repeat center center;
//   background-size: 24px;

//   backdrop-filter: blur(5px);
//   border-radius: 48px;
//   margin: 0;
//   border: none;

//   position: fixed;
//   left: 24px;
//   top: 0px;
// }




// .main-logo{
//   position: absolute;
//   left: 20px;
//   height: 32px;
//   width: 32px;
//   display: block;
// }








// .chat-container{

// }

// .receiver-chat p, .sender-chat p{
//   font-size: 16px;
//   line-height: 1.2;
//   text-align: left;
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   display: block;
// }

// .receiver-chat p{
//   color: rgba(18, 63, 114, 1.0);
// }

// .sender-chat p{
//   color: #fff;
// }

// .receiver-chat{
//   background: white;
//   border: 1px solid rgba(212, 222, 231, 1.0);
//   color: #fff;
//   border-radius: 21px;
//   padding: 20px;
//   position: absolute;
//   left: 48px;
//   width: 100%;
//   min-width: 45vw;
//   max-width: 60vw;
// }

// .receiver-chat-container{
//   width: auto;
//   height: 96px;
//   max-width: 90vw;
//   position: absolute;
//   left: 0px;
//   top: 36vh;
// }

// .receiver-avatar{
//   height: 40px;
//   width: 40px;
//   border-radius: 50%;
//   background: red;
//   position: absolute;
//   left: 0;
//   bottom: 0;
// }

// .sender-chat{
//   background: linear-gradient(#858DFF 0%, #858DFF 100%);
//   color: #fff;
//   border-radius: 21px;
//   padding: 20px;
//   width: auto;
//   max-width: 60vw;
//   position: absolute;
//   right: 0;
//   top: 6vh;
// }

// .sender-chat.two{
//   top: 20vh;
// }




// .reply-container{
//   position: fixed;
//   bottom: 24px;
//   left: 0;
//   right: 0;
//   margin: auto;
//   width: calc(100vw - 48px);
//   height: 56px;
//   border: 1px solid rgba(212, 222, 231, 1.0);
// /*  padding: 16px;*/
//   border-radius: 21px;
//   overflow: hidden;
// }


// input{
//   border: none;
// }

// .reply-input{
// /*  border: 1px solid rgba(212, 222, 231, 1.0);*/
//   padding: 0 16px;
//   background: rgba(255, 255, 255, 1.0);
//   border-radius: 21px;
//   height: 56px;
//   position: absolute;
//   width: 100%;
//   left: 0;
// }
