@media (min-width: 400px) {
  	.content{
	  padding: 0 24px;
	  max-width: 680px;
	  position: relative;
	  display: block;
	  margin: 0 auto;

	  &.left{
	    padding: 0;
	    margin-left: calc(24px + 6vw);
	    max-width: 640px;
	  }
	  &.wide{
	  	margin-left: 6vw;
	  }
	}

	footer{
  		.main-footer{
  			padding: 8% calc(24px + 6vw) 64px;
    		// padding: 13vh 6vw 64px;
    		background: #fff;
    	}
    } 

    .color{
    	padding: 80px 0;
    	margin: 0;
    }

    .hidden-mobile{
    	display: block;
    }

    .navbar{
    	backdrop-filter: blur(0px);
    	background: transparent;
    }
    .chatInterfaceAnimation{
    	.navbar{
    		backdrop-filter: $mainBlur;
    		background: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.1));
    	}
    }

    .calendly-inline-widget{
	  height: 100vh!important;
	}

    .modal{
    	// height: calc(100% - 104px);
    	height: 100%;
    	background: transparent;
    	box-shadow: none;
    	z-index: 51;
    	border-radius: 0;
    }
    .modal-overlay{
    	z-index: 50;
    }
    .button{
    	&.close-modal-btn{
    		height: 48px;
    		z-index: 80;
    	}
    }

    .boxed{
	  padding: 104px 88px;
	  border-radius: 40px;
	}

	img{
		&.small{
			margin: 0;
		}

		&.despre{
			border-radius: 40px;
		}
	}

	.graphics{
		border-radius: 40px;
		margin-left: 80px;
	}

}


@media (max-width: 320px) {
   
}
