button{
	border: none;
	cursor: pointer;

	&.main{
		margin: 0 auto;
		padding: 0 12px;
		display: block;
		border: none;
		background: transparent;
		font-size: $paragraphTextSize;
		color: $solidWhite;
		width: 100%;
		height: 64px;
		cursor: pointer;
	}

	&:hover{
		opacity: 0.5;
	}
}

.button{
	font-size: $buttonTextSize;
	font-weight: $semiboldWeight;
	padding: 0 16px;
	
	
	background: $solidBlack;
	color: $solidWhite;
	border-radius: 40px;

	text-align: center;
	display: block;
	max-width: 400px;
	transition: all 0.3s ease;

	

	&.show-hide-chat-btn{
		text-decoration: none;
		position: absolute;
		top:20px;
		right:20px;
		height: 44px;
		width: 44px;
		background: url('../assets/img/ic-back.svg')no-repeat center center;
		backdrop-filter: $overlayBlur;
		z-index: 2;

		&.hide{
			height: 56px;
			width: 56px;
			top: 16px;
			right: 16px;
			transform: rotate(-90deg);
		}

		&.show{
			height: 56px;
			width: 56px;
			top: 16px;
			right: 16px;
			transform: rotate(90deg);
		}
	}

	&.show-chat-btn{
		position: fixed;
		display: flex;
		padding: 24px;
		bottom: 0px;
		right: 15vw;
		height: 80px;
		width: 400px;
		background: $transparentWhite;
		backdrop-filter: $overlayBlur;
  		box-shadow: 0px -10px 200px rgba(0, 0, 0, 0.50);
		border-radius: 24px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		z-index: 2;
		backdrop-filter: $overlayBlur;
	}

	&.close-modal-btn{
		position: fixed;
		color: $solidBlack;
		text-align: left;
		padding: 0 64px;
		text-decoration: none;
		
		left: 0;
		top: 8px;
		height: 32px;
		width: 100%;
		background: url('../assets/img/ic-close.svg')no-repeat center center;
		background-size: 24px;
		background-position: 24px;
		
	}

	&.small-btn{
		height: 32px;
		line-height: 32px;
		padding: 0 24px;
		
		&.schedule{
			position: absolute;
			right: 72px;
			top: 28px;
		}

		&.contact{
			position: absolute;
			right: 24px;
			top: 28px;
		}
	}

	&.big-btn{
		height: 64px;
		line-height: 64px;
		padding: 0 10%;
		border-radius: 40px;
	}

	&.ultra-big-btn{
		height: 80px;
		max-width: 100%;
		line-height: 80px;
		padding: 0 10%;
		border-radius: 40px;
		font-size: $chatTextSize;
	}

	&.help-btn{
		text-decoration: none;
		position: fixed;
		right: 24px;
		top: 28px;
		height: 32px;
		width: 32px;
		background: url('../assets/img/ic-help.svg')no-repeat center center;
	}

	&.close-btn{
		text-decoration: none;
		position: absolute;
		left: 24px;
		top: 28px;
		height: 32px;
		width: 32px;
		background: url('../assets/img/ic-close.svg')no-repeat center center;
		backdrop-filter: $overlayBlur;
	}

	&.back-btn{
		text-decoration: none;
		position: absolute;
		left: 24px;
		top: 28px;
		height: 32px;
		width: 32px;
		background: url('../assets/img/ic-back.svg')no-repeat center center;
		backdrop-filter: $overlayBlur;
	}

	&.home-btn{
		text-decoration: none;
		position: absolute;
		left: 8px;
		top: 16px;
		height: 32px;
		width: 32px;
		background: url('../assets/img/symbol.svg')no-repeat center center;
		background-size: contain;
	}

	&.send-btn{
		text-decoration: none;	
		height: 64px;
		width: 80px;
		background: url('../assets/img/ic-send.svg')no-repeat center center;
		position: absolute;
		right: 0px;
		top:0;
	}


	&.choice{
		display: block;
		background: $solidWhite;
		height: 64px;
		min-width: 88px;
		margin: 0 4px;
		padding: 0 24px;

		font-size: $chatTextSize;
		color: $solidBlack;

		border-radius: 24px;
		box-shadow: 0px 8px 380px $solidBlack;

		&.primary{
			opacity: 1;
		}
		&.secondary{
			font-weight: $regularWeight;
		}
	}

}

.submit{
	background: transparent;
	border: none;
	color: $solidWhite;
	font-size: $captionTextSize;
	font-weight: $semiboldWeight;
	line-height: $mainLineHeight;
	text-transform: uppercase;
	height: 40px;

	&:hover{
		opacity: 0.5;
	}
}