//BRANDING COLORS

//Main
$solidBlack: rgba(15, 1, 31, 1.0);
$solidWhite: rgba(255,255,255, 1);
$transparentWhite: rgba(255,255,255, 0.5);
$transparentBlack: rgba(0,0, 0, 0.3);

$lightGrey: rgba(235, 235,235, 1.0);
$mediumGrey: rgba(137, 144, 155, 1.0);
// $mediumGrey: rgba(80,85,92, 1.0);

//Specific Colors
$mainColor: rgba(45, 149, 255, 1.0);
$secondaryColor: rgba(56, 0, 56, 1.0);


//Gradients
// $mainGradient: linear-gradient(45deg, #E171FF, #6A71FF, #69DAFF);

//BLUR
$mainBlur: blur(10px);
$overlayBlur: blur(30px);
$bigBlur: blur(150px);

//MARGINS & PADDINGS
$smallMargin: 12px;
$mainMargin: 24px;
$bigMargin: 40px;

//BORDER RADIUS
$roundRadius: 64px;
$mainRadius: 4px;
$smallRadius: 2px;

//TYPOGRAPHY
$mainFont: 'Montserrat', sans-serif;

//Font Size
$ultraBigTextSize: clamp(4.4rem, 2rem + 4.87vw, 8.25rem);
$titleTextSize: clamp(2.875rem, 2.25rem + 2.5625vw, 6.125rem);
// $titleTextSize: 36px;
$subtitleTextSize: clamp(1.45rem, 1rem + 0.3vw, 1.6rem);
$paragraphTextSize: clamp(1.125rem, 1.0625rem + 0.1563vw, 1.25rem);
$chatTextSize: 15px;
// $paragraphTextSize: 17px;
$captionTextSize: 11px;
$xsTextSize: 9px;
$buttonTextSize: 13px;

//Font Weight
$boldWeight: 900;
$semiboldWeight: 700;
$mediumWeight: 500;
$regularWeight: 300;

//Font Line Height
$mainLineHeight: 1.6;
$smallLineHeight: 1.2;
$noLineHeight: 1;

//Buttons
$iconButtonHeight: 44px;
$normalButtonHeight:48px;
$bigButtonHeight: 64px;
