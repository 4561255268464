.static-navbar{
  border-radius: 40px;
  background: $transparentWhite;
  border: 1px solid rgba(255, 255, 255, 0.35);
  backdrop-filter: $overlayBlur;
  padding: 8px;

  position: fixed;
  left: 0;
  right: 0;
  top: 32px;
  margin: auto;
  width: 100%;
  max-width: 780px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);

  z-index: 50;

  .nav{
    float: left;
    margin-left: 16px;
    .nav-link{
      padding: 8px 20px;
      font-size: $chatTextSize;
      line-height: 48px;
      border-radius: 40px;
      border: 1px solid transparent;
      transition: all 0.3s ease;

      &.active{
        color: $mediumGrey;
        
        border: 1px solid $lightGrey;
      }

      &:hover{
        opacity: 0.5;
      }

    }

  }

  .nav-button{
    background: $solidBlack;
    color: $solidWhite;
    font-size: $chatTextSize;
    font-weight: $boldWeight;
    border-radius: 32px;
    padding: 4px 24px;
    line-height: 40px;
    float: right;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

    &:hover{
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0);
      opacity: 0.9;
    }
  }
}




.logo{
  position: relative;
  width: 56px;
  height: 56px;
  // background: url('../assets/img/logo.svg') no-repeat center center;
  background-size: 100%;
  
  &.symbol{
    position: relative;
    width: 104px;
    height: 104px;
    background: url('../assets/img/symbol.svg') no-repeat center center;
    background-size: 100;
  }

  &.mobile{
    width: 64px;
    height: 64px;
    margin: 0;
  }

  &.nav{
    width: 120px;
    height: 88px;
    margin: 0px 0;
    position: absolute;
    top: 0px;
    left: 24px;
    background-size: 100%;
  }

  &.center{
    display: block;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }

  &.splash{
    display: block;
    margin: auto;
    width: 88px;
    height: 88px;
  }

  &.fixed{
    position: fixed;
    top: 32px
  }

  &.white{
    // background: url('../assets/img/logo-white.svg') no-repeat center center;
    background-size: 100%;
    opacity: 1;
    margin: 0 auto;
  }
}




.chat-bar{
  position: fixed;
  bottom: 0;
  z-index: 20;
}


.chat-box{
  position: fixed;
  bottom: 0;
  right: 13vw;
  background: rgba(25, 25, 25, 0.5);

  background: $lightGrey;
  box-shadow: 0px -10px 200px rgba(0, 0, 0, 0.50);

  max-height: 680px;
  width: 100%;
  max-width: 400px;

  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 20;

  /* Hide scrollbar */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
.inner-chat-box{
  position: relative;
  width: 100%;
  height: 680px;
  // background: red;
  z-index: 10;
  overflow: hidden;
}

.navbar{
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;

    height: 88px;
    width: 100%;

    background: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.1));
    backdrop-filter: $mainBlur;   
    z-index: 4; 
  }


  // overflow: hidden;
  // max-width: 400px;
  // width: 100%;
  // position: relative;
  // z-index: 3;
  // padding-bottom: 40vh;
  

  .reply-container{
    position: fixed;
    bottom: 40px;
    left: 32px;
    right: 32px;
    background: transparent;
    height: 64px;
    // display: flex;

    .reply-cta{
      
      .reply-input{
        position: absolute;
        left: 0px;
        right: 0px;
        border-radius: 24px;
        padding: 0 12px;
        background: $transparentWhite;
        backdrop-filter: $overlayBlur;
        box-shadow: 0px 8px 380px $solidBlack;
        width: auto;
        line-height: 64px;
        font-size: 17px;
        border-radius: 24px;
        
      }

      .reply-choice{
        display: flex;
        float:right;
      }

    }

  }



.bottom-fix{
  position: relative;
  height: 10px;
  width: 100%;
  float: left;
  background: red;
}

.chat{
  scroll-behavior: smooth;
  // background: blue;
  overflow-y: auto;
  max-height: 100vh;
  padding-bottom: 30vh;
  position: fixed;
  bottom: 0;
  top: 0;
}
.chat-container{
  // position: absolute;
  // scroll-behavior: smooth;
  width: auto;
  position: relative;
  // height: 400px;
  // background: green;
  float: left;
  
  padding: 104px 12px 0;
  // max-height: 670px; /* Adjust the height as needed */

  // position: fixed;
  // position: absolute;
  // bottom: 0;
  // top:0px;
  // left: 0;
  // right: 0;
  // height: 68%;
  // max-height: 100vh; /* Adjust the height as needed */
  // overflow-y: auto;
  // margin: 0 12px;
  // padding: 104px 12px 50vh;


  .booble{
    position: relative;
    background: $mainColor;
    padding: 16px;
    border-radius: 24px;
    margin: 20px 0;
    max-width: 80%;
    float:left;
    
    &.twin{
      margin: 1px 0;
    }

    .reaction{
      .hug{
        animation: shake 3s ease;
      }
      .heart{
        animation: pulse 3s ease;
      }
    }

    .message{
      color: $solidWhite;
      font-size: $chatTextSize;
      font-weight: $mediumWeight;
      margin: 0;
      padding: 0;
    }

    &.user-input{
      margin: 24px 0;
      background: $solidWhite;
      border: 1px solid $lightGrey;
      float: right;

      .message{
        color: $solidBlack;
      }

      .reaction{
        background: $solidWhite;
        box-shadow: 0px 4px 8px $lightGrey;
        border-radius: 32px;
        width: 32px;
        height: 32px;
        position: absolute;
        bottom: -20px;
        right: 16px;
        text-align: center;
        line-height: 32px;
      }

    }

  }

  .indicator{

    span{
      font-family: $mainFont;
      color: $mediumGrey;
      font-size: $captionTextSize;
      font-weight: $semiboldWeight;
    }

    &.status{
      width: 100%;
      float: right;

      span{
        margin: 4px 24px;
      }
    }

    &.typing{
      width: 100%;
      float: right;
      margin: 20px 0;

      .avatar{
        position: absolute;
        left: 8px;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        overflow: hidden;
        background: url('../assets/img/profile.png') no-repeat center center;
        background-size: 100%;
      }

      span{
        margin: 0 40px;
        line-height: 24px;
      }
    }
     
  }
  /*to be changed*/
  // .calendly-inline{
  //   margin: 20px 0;
  //   position: relative;
  //   background: red;
  // }

}

.reaction-overlay{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: $transparentWhite;
  backdrop-filter: $overlayBlur;
  z-index: 4;

  .reaction-animation{
    font-size: 40vw;
    line-height: 40vw;
    position: absolute;
    top: 33vh;
    left: 0;
    right: 0;
    text-align: center;

    z-index: 4;

    .hug{

    }
    .love{

    }
  }
}

.landing-video{
  height: 100%;
  width: 100%;
  object-fit: cover;
}